<template>
  <master-detail
    formTitle="Cadastro tipo Boleto"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    descriptionProperty="nome"
    has-year-filter
  ></master-detail>
</template>

<script>
import yearsOptions from '@/helpers/yearsOptions';

export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    resourceUrl: function () {
      return `/v1/faturamento/projext/tipoBoleto/${this.getClient().clientId}`;
    },
    resourceInvestimento: function () {
      return this.apiResource("/v1/faturamento/projext/tipoInventimento");
    },
  },
  created: function () {
    this.resourceInvestimento.get().then((response) => {
      this.opts.investimento = response;
    });
  },
  data: function () {
    return {
      cols: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "data_pagamento",
          name: "Data Pagamento",
          type: this.$fieldTypes.DATE,
          colSize: 6,
        },
        {
          key: "valor",
          name: "Valor",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
        },
        {
          key: "tipoinvestimentoId",
          name: "Tipo Investimento",
          type: this.$fieldTypes.SELECT,
          rel: { to: "investimento", key: "id", name: "tipo_investimento" },
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: "trimestre_obrigacao",
          name: "Trimestre Obrigação",
          type: this.$fieldTypes.SELECT,
          rel: { to: 'trimestres', key: 'value', name: 'text' },
          colSize: 6,
        },
        {
          key: "anoBase_obrigacao",
          name: "Ano Obrigação",
          type: this.$fieldTypes.SELECT,
          rel: { to: 'anos', key: 'value', name: 'text' },
          colSize: 6,
        },
        {
          key: "trimestre_credito",
          name: "Trimestre Crédito",
          type: this.$fieldTypes.SELECT,
          rel: { to: 'trimestres', key: 'value', name: 'text' },
          colSize: 6,
        },
        {
          key: "anoBase_credito",
          name: "Ano Crédito",
          type: this.$fieldTypes.SELECT,
          rel: { to: 'anos', key: 'value', name: 'text' },
          colSize: 6,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        enquadramento: [],
        trimestres: [
          {
            text: 'Primeiro',
            value: 1,
          },
          {
            text: 'Segundo',
            value: 2,
          },
          {
            text: 'Terceiro',
            value: 3,
          },
          {
            text: 'Quarto',
            value: 4,
          },
        ],
        anos: yearsOptions,
      },
    };
  },
};
</script>
